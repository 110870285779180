import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import customMedia from '../../utils/customMedia'

const HeaderLink = ({ clickCallback, text }) => {
  return <Wrapper onClick={clickCallback}>{text}</Wrapper>
}

HeaderLink.propTypes = {
  text: PropTypes.string,
  clickCallback: PropTypes.func,
}

export default HeaderLink

const Wrapper = styled.a`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  cursor: pointer;

  ${customMedia.greaterThan('desktop')`
    margin-bottom: 10px;
    font-size: 18px;
  `};
`
