import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FeatureBox from '../components/share/featureBox'
import FooterBox from '../components/share/footerBox'
import GreyBackground from '../components/share/greyBackground'
import HeaderLink from '../components/header/headerLink'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import Quote from '../components/share/quote'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "proposals/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerHand: file(relativePath: { eq: "proposals/headerHand.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tomPanos: file(relativePath: { eq: "brandAgentIcon/tom_panos.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    giants: file(relativePath: { eq: "proposals/giants.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brandGroup: file(relativePath: { eq: "proposals/brandGroup.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    proposals: file(relativePath: { eq: "index/proposals.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    phone: file(relativePath: { eq: "share/phone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    management: file(relativePath: { eq: "index/management.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    createProposals: file(
      relativePath: { eq: "proposals/createProposals.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    fastestResponse: file(
      relativePath: { eq: "proposals/fastest_response.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessible: file(relativePath: { eq: "proposals/accessible.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    profileVideo: file(relativePath: { eq: "share/glasses.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    contactList: file(relativePath: { eq: "proposals/contact_list.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    insight: file(relativePath: { eq: "proposals/insight.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    watch: file(relativePath: { eq: "proposals/watch.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    custom: file(relativePath: { eq: "proposals/custom.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    invoice: file(relativePath: { eq: "proposals/invoice.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    testimonial: file(relativePath: { eq: "proposals/testimonial.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    neilPatel: file(relativePath: { eq: "proposals/neil_patel.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    johnCunningham: file(
      relativePath: { eq: "brandAgentIcon/john_cunningham.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

class ProposalsPage extends Component {
  _scrollToSection = title => {
    this[title].scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.5"
                style={{ height: '600px' }}
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <HeaderTitle>
                    Send proposals faster, win more listings.
                  </HeaderTitle>

                  <HeaderBody>
                    Send more winning proposals with the streamlined creation of
                    quotes, pre-lists, comparable market reports and up to the
                    minute tracking and reporting that let’s you strike while
                    the iron is hot.
                  </HeaderBody>

                  <HeaderLink
                    text="What’s making vendors choose your competitors?"
                    clickCallback={() => this._scrollToSection('firstSection')}
                  />

                  <TypeFormButton text="GET STARTED FOR FREE" />
                </ContentWrapper>

                <HeaderHandImg
                  fluid={data.headerHand.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>

              <ContentWrapper contentRef={el => (this.firstSection = el)}>
                <IntroRow
                  title={`“Sorry, we’ve gone with another agent.” ~ Not again....`}
                  content={
                    <Fragment>
                      <Paragraph>
                        How would you feel if you’d spent months or even years
                        nurturing a lead, but when the time came for them to
                        sell, another agent sent them a more professional, more
                        informative, custom branded, interactive and
                        mobile-friendly digital proposal, securing the listing
                        instead of you?
                      </Paragraph>

                      <Paragraph>
                        <strong>
                          You’ve put in too much groundwork to lose it right
                          before the finish line.{' '}
                        </strong>
                        Using Realhub’s real estate-focused proposal software
                        you’ll be the one converting sales out from under your
                        competitor's feet.
                      </Paragraph>

                      <Quote
                        agentImage={data.tomPanos.childImageSharp.fluid}
                        desc={`“There are only two reasons why you don’t have every listing in your market. You were not called in, or you were not good enough once you were there.”`}
                        agencyName="Real Estate Trainer"
                        agentName="Tom Panos"
                      />
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                background={data.giants.childImageSharp.fluid}
                height="500px"
                opacity="0.5"
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    image={data.brandGroup.childImageSharp.fluid}
                    title={
                      <Fragment>
                        You’re not just{' '}
                        <strong>competing against agents</strong>, you’re{' '}
                        <strong>competing against giants.</strong>
                      </Fragment>
                    }
                    descWidth="50%"
                    content={
                      <Fragment>
                        <Paragraph>
                          The rise of tech behemoths like Amazon and Uber has
                          changed the world, and not just for their own
                          industries. It’s lifted consumers expectations to
                          almost unachievable heights.
                        </Paragraph>

                        <Paragraph>
                          Competing with John Smith Real Estate from around the
                          corner and his loose leaf, in-house printed word doc
                          is easy. Competing with billion-dollar tech companies
                          is harder.{' '}
                          <strong>
                            You need to stand on the shoulders of powerful
                            technology.
                          </strong>
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  image={data.createProposals.childImageSharp.fluid}
                  title="Create and send proposals from anywhere, at any time."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>Intuitive Mobile and Desktop Builder</strong>
                      </Paragraph>

                      <Paragraph>
                        You can’t control when a seller might contact you, so
                        you need to be ready no matter what. You need to have
                        the tools at hand to prepare and send a professional and
                        informative proposal that gets you through the gates.
                      </Paragraph>

                      <Paragraph>
                        <strong>Integrated historical sales data</strong>
                      </Paragraph>

                      <Paragraph>
                        A killer presentation has to include up to date market
                        research including which markets to access. It will be
                        tailored to the vendor and their property. And finally,
                        the proof to back it up.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <GreyBackground style={{ marginBottom: 0, padding: '10px 0' }}>
                <ContentWrapper direction="row">
                  <FeatureBox
                    image={data.proposals.childImageSharp.fluid}
                    title="Get set up"
                    subTitle="Branded custom templates"
                    content="Get your brand set up in minutes, without onboarding costs for the default themes."
                  />
                  <FeatureBox
                    image={data.phone.childImageSharp.fluid}
                    title="Send Away"
                    subTitle="Start sending proposals"
                    content="Instantly send your proposal worldwide via SMS or email with trackable links."
                  />
                  <FeatureBox
                    image={data.management.childImageSharp.fluid}
                    title="Win Listings"
                    subTitle="Increase your success %"
                    content="Educate your vendor and strike while the iron is hot with SMS alerts on open."
                  />
                </ContentWrapper>
              </GreyBackground>

              <ImageBackground
                background={data.fastestResponse.childImageSharp.fluid}
                height="500px"
                opacity="0.2"
              >
                <ContentWrapper>
                  <IntroRow
                    title={
                      <Fragment>
                        The fastest response
                        <br />
                        <strong>wins the listing.</strong>
                      </Fragment>
                    }
                    descWidth="50%"
                    content={
                      <Fragment>
                        <Paragraph>
                          Companies that respond to leads in under an hour are{' '}
                          <strong>
                            7x more likely to convert leads to sales.
                          </strong>{' '}
                          Imagine if you could respond in minutes.
                        </Paragraph>

                        <Paragraph>
                          So get the right gear, and get ready to race.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  image={data.accessible.childImageSharp.fluid}
                  title="Accessible Anywhere"
                  descWidth="60%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Responsive Mobile, Tablet & Desktop friendly design
                          built for speed.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Just like you can’t control when a vendor will need your
                        proposal, you can’t control how they will consume it.
                        Realhub gives you the ability to send one link, that
                        works everywhere, on all the latest operating systems
                        and browsers, across any device from phones and tablets
                        to the desktop. You will always look good.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.contactList.childImageSharp.fluid}
                  title="Get Personal. Gain Listings"
                  descWidth="60%"
                  reverse
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Personalise and send proposals to anybody. Including
                          your CRM contact list.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Personalisation is incredibly important in the current
                        digital landscape. If people are asking for your
                        informed opinion on selling their property, you can bet
                        they are expecting a proposal that has been prepared
                        only for them, not a cut & paste of the last property
                        you appraised. Use your own data to populate the
                        proposal automatically so you’re always sending a custom
                        proposal.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.profileVideo.childImageSharp.fluid}
                  descWidth="60%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Make sure to include a youtube or Vimeo hosted agent
                          profile video.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        People are time poor. We rarely like to sit down and
                        read huge chunks of text. Adding a profile video to your
                        proposal can go a long way in grabbing their attention
                        and letting them learn more about you, building trust in
                        you.
                      </Paragraph>

                      <Paragraph style={{ fontWeight: 600 }}>
                        The rate at which a vendor remembers what they’ve read
                        is as low as 10%. With video, retention rates skyrocket
                        to 95%.
                      </Paragraph>
                      <Paragraph>
                        Users spend up to 88% more time on a website that
                        includes a video.
                      </Paragraph>
                      <Paragraph>
                        Including an agent profile video with vendor
                        testimonials is a great way of highlighting your value
                        to the transaction. The best performing pre-lists
                        include a quick introduction video personalised
                        specifically to each proposal meeting, speaking directly
                        to the vendor and mentioning them by name.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                background={data.insight.childImageSharp.fluid}
                height="500px"
                opacity="0"
              >
                <WatchImg
                  fluid={data.watch.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>

              <ContentWrapper style={{ marginTop: 120 }}>
                <IntroRow
                  title="Get a better understanding of vendor’s viewing habits"
                  descWidth="60%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Make more informed follow-ups and strike while the
                          iron is hot.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Instant SMS alerts, time a viewer spent on your proposal
                        and how many times they’ve viewed it are incredibly
                        valuable for converting more listings. Whether a vendor
                        clicks on your proposal within a minute of you sending
                        it, or in 8 months when they finally decide they are
                        ready to sell, you can be on the phone, having an
                        informed and directed conversation.
                      </Paragraph>

                      <Paragraph>
                        Use data to predict income and increase conversions.
                        Comprehensive reporting will help you formulate the best
                        recipe for success.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <GreyBackground>
                <ContentWrapper>
                  <IntroRow
                    image={data.custom.childImageSharp.fluid}
                    title={
                      <Fragment>
                        Your brand, your personality. <br />
                        Not a cookie cutter design.
                      </Fragment>
                    }
                    descWidth="60%"
                    content={
                      <Fragment>
                        <Paragraph>
                          You probably don’t fit the mould of a ‘typical’ agent,
                          or you wouldn’t be here. Everything that got you to
                          where you are today makes you unique, and it is what
                          makes a vendor choose you over the competition.
                        </Paragraph>
                        <Paragraph>
                          Built here in Australia we can offer completely custom
                          one-off templates that perfectly fit your brand. You
                          can have this designed by our design team, your
                          professional third party design studio, or our
                          talented front-end designers. No one will confuse you
                          with the competition.
                        </Paragraph>

                        <Paragraph>
                          <BlueText>“Can I start without it?”</BlueText>
                        </Paragraph>

                        <Paragraph>
                          No dramas at all. Every single proposal is unique to
                          you, your brand, and has a completely customised
                          layout which means no two proposals are the same, even
                          if you don’t have a completely custom build.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </GreyBackground>

              <ContentWrapper>
                <IntroRow
                  image={data.invoice.childImageSharp.fluid}
                  title={
                    <Fragment>
                      Provide accurate and <br />
                      transparent pricing.
                    </Fragment>
                  }
                  reverse
                  descWidth="60%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Become the accounting team’s new best friend.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Accidentally underquoting a realestate.com.au listing,
                        or over quoting a newspaper ad could cost you or the
                        vendor thousands, and lead to unprofessional
                        conversations to smooth over. Don’t leave pricing up to
                        chance, use a proposal platform with your pricing and
                        quoting built in.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.testimonial.childImageSharp.fluid}
                  title={
                    <Fragment>
                      Build trust, <br />
                      provide social proof.
                    </Fragment>
                  }
                  descWidth="60%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          How important is social proof to converting prospects
                          into sellers?
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Social proof is a socio-psychological principle so
                        ingrained in our behaviour that many of us don’t even
                        realize it’s impact. The idea is that we are influenced
                        by other people’s experiences. If we see someone get
                        burned by a hot pan, we are not going to touch that pan
                        ourselves.
                      </Paragraph>
                      <Paragraph>
                        By highlighting what their neighbours are doing (selling
                        with you) you can very quickly build a large amount of
                        trust, even if they don’t personally know the people
                        providing their testimonials.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <Quote
                  agentImage={data.neilPatel.childImageSharp.fluid}
                  desc={`“63% of consumers indicate they are more likely to purchase from someone if they are shown ratings and reviews.”`}
                  agencyName="NEILPATEL"
                  agentName="Neil Patel"
                />
              </ContentWrapper>

              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>

                  <TypeFormButton />
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>

              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>

              <ContentWrapper>
                <Proof>Speaking of social proof...</Proof>
                <SubProof>Who are some of our customers?</SubProof>
                <BrandBanner />
              </ContentWrapper>

              <ContentWrapper>
                <Quote
                  agentImage={data.johnCunningham.childImageSharp.fluid}
                  desc={`“Sometimes you’ve got to make a quantum leap, you’ve gotta take a leap of faith with innovation and lead the way. Change is inevitable. It’s the only constant. Embrace it, go with it, because if you don’t you’ll just get left behind.”`}
                  agencyName="Cunninghams"
                  title="Founder & Director"
                  agentName="John Cunningham"
                />
              </ContentWrapper>

              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default ProposalsPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 500px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 2;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const HeaderHandImg = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 200px;
  height: 200px;
  bottom: -48px;
  right: -20px;
  z-index: -1;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 350px;
    bottom: -70px;
    right: 0px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 450px;
    height: 350px;
    bottom: -50px;
    right: -100px;
  `};
`

const WatchImg = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 300px;
  height: 200px;
  bottom: -80px;
  right: -10px;

  ${customMedia.between('mobile', 'tablet')`
    width: 300px;
    height: 350px;
    bottom: -125px;
    right: 70px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 300px;
    height: 300px;
    bottom: -100px;
    right: 50px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const BlueText = styled.span`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 22px;
  letter-spacing: 1px;
`

const Proof = styled.div`
  font-size: 25px;
  margin-bottom: 15px;
`

const SubProof = styled.div`
  font-size: 16px;
  color: #8c8c8e;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
